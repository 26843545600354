require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
            // scrollIntoView();
        }
        else if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        }
        else if (e.target.classList.contains('icon')) {
            toggleMobileMenu();
        }
    });
}

function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
    toggleMobileMenu(false);
}

function toggleMobileMenu(state) {

    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition
        ? header.classList.add('nav-visible')
        : header.classList.remove('nav-visible');
}
function toggleFaq(faqBtn, options = {}) {

    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        // faq.scrollIntoView();
        scrollToElement(faq, 'auto');
    }
}
